<template src="./HallBuyByTIDaEID.html"></template>

<script>
import Hall from "@/components/hall/Hall";
import {mapGetters} from "vuex";
import store from "@/store";
import {AUTH_REQUEST} from "@/store/actions/auth";
export default {
  name: "HallBuyByTIDaEID",
  components: {Hall},
  data: () => ({

    isLocalAuth: false,

    show_id:    0,
    event_id:   0,
    pushka:     0,
    culture_id: 0,
    theater:    0,

    //actual:false,
    show_dialog: false,
    show_dialog_info: false,
    text_dialog_info: "",

    event:{},

    eventloaded:false,
    showheader: "Начинаем",

    view:2,
    options:[],

    hallConfig:{
      base:{
        type: 'user',
        mode: 'event',
        view: 'sale',
        event_id: 0,
      },
      options:{
        zone:true,
        discount:false,
        armour:false,
        puskin:false

      },
      additional:{
        zone_n:0,

        monger_mode: "show_all",
        monger_id:0,
        seat_status_id:0
      }
    }, //hallConfig

    order:{

      email:'',
      phone:'',
      fio:'',

      event_id:   0,
      show_id:    0,
      culture_id: 0,
      pushka:     0,

      link: false,
      payment_type: 0,

      seats:[],

      show_name:'Спектакль не выбран',

      total:{
        price:0,
        count:0,
      },

      date: 'Сегодня',


    }


  }),

  created() {

    //console.log(this.$route.params);

    if (this.$route.params.tid) {

      //console.log(this.$route.params)
      //console.log('tid:', this.$route.params.tid);
      //return '/buy/:eid';

      // входим под учеткой в этот театр
      const username = "api_user@zakaz.cloud";
      const password = "EubMWqYVyT3W3QFFo6b3JVQCk4wLmwo";
      const isAdmin = false;
      const theater_select = this.$route.params.tid;

      this.showheader = "Подключаемся";

      //console.log('before');

      store.dispatch(AUTH_REQUEST, { username, password, theater_select, isAdmin }).then(() => {
        //this.$router.push("/buy/12");

        this.showheader = "Подгружаем"
        //console.log('ttttt');

        // открываем страницу спектакля
        if (this.$route.params.eid){

          this.showheader = "Открываем";

          console.log('eid:', this.$route.params.eid);

          this.theater= this.$route.params.tid;

          this.event_id = this.$route.params.eid;
          this.hallConfig.base.event_id = this.event_id;

          console.log(this.event_id);

          this.isLocalAuth = true;

        }
        else{
          console.log('no eid goto afisha');
        }

      });

      console.log('after');







      //return '/with-params/:td'


    }
    else{
      console.log('нет театра нет входа')
      return '/none'
    }












  },

  computed:{
    ...mapGetters(["getColorZones"]),

    getPricesColors(){


      let price = [];

      let prices = this.event.event.prices.split('/');

      for (let i = 0; i<this.event.price_map.zone_count; i++){

        let t = {};
        t.id = i;
        t.price = prices[i];
        t.color = this.getColorZones[i+1];

        price.push(t);
      }

      //console.log(price)

      return price;
    },




  },

  mounted() {

    console.log('mount');
  },



  methods:{



    clear_order(){

      this.order = {

        event_id:   this.event_id,
        pushka:     this.pushka,
        show_id:    this.show_id,
        culture_id: this.culture_id,

        payment_type: 0,

        seats:[],

        total:{
          price:0,
          count:0,
        }

      }

    },

    // eslint-disable-next-line no-unused-vars
    onSeatClick(seat_id, status, raw_seats, raw_event){
      //
      // let t = this.order.seats.findIndex(seat=>(seat===seat_id));
      // console.log(t);

      let tt = this.$refs.hall.getSelected();

      if (this.$route.params.tid==9){
        if (tt.length>1){
          this.$refs.hall.clearSelected()
          this.$refs.hall.canvas_mouse_down(raw_event)
          return
        }
      }

      let ids = [];
      let seats = [];

      let prices = this.event.event.prices.split('/');

      let t = {};
      let total = {
        count:0,
        price:0
      };

      tt.forEach(seat=>{
        //console.log(seat);
        ids.push(seat.base.id);

        t = {
          id:seat.base.id,
          zone: seat.additional.price_zone,
          price: prices[seat.additional.price_zone-1],
        }

        total.count++;
        total.price +=  parseInt(t.price)

        seats.push(t);

      })

      console.log(seats, total);


      this.order.seats = ids;
      this.order.total = total;

      // отправлять эти данные в store
      //this.$store.dispatch('updateOrder', this.order);

      this.$store.commit('updateOrder', this.order)


    },

    onReadyHall(){

      console.log('hall is ready');

      this.loadEventAdm();


    },

    onLoadedHall(event){

      console.log(event);

      if ('message' in event.data.event){
        if (event.data.event.message.length>3){
          this.show_dialog_info = true;
          this.text_dialog_info = event.data.event.message;
        }
      }


      console.log(event.data.event.date.actual);

      if (event.data.event.date.actual){
        this.show_dialog = false;
        console.log('!!')
      }
      else{
        this.show_dialog = true;
      }

      this.event = event.data;
      this.eventloaded = true;

      this.order.event_id       = this.event.event.id;
      this.order.pushka         = this.event.event.pushka;
      this.order.culture_id     = this.event.show.culture_id;
      this.order.show_id        = this.event.show.id;
      this.order.show_name      = this.event.show.name;
      this.order.date           = this.event.event.timedate;

      if (this.event.event.pushka=="1"){
        this.order.payment_type = 30;
      }

      this.showheader =  this.event.show.name +' ('+ this.event.show.age+') '+ this.event.event.timedate;


      // let data = {
      //   view: this.hallConfig.base.view,
      //   options: this.hallConfig.options,
      //   additional: this.hallConfig.additional,
      // }
      //
      // this.$refs.hall.updateMode(data);

      this.$nextTick(() => {
        this.$refs.hall.full_redraw();
        this.$nextTick(() => {
          this.$refs.hall.full_redraw();
        })
      })

    },

    loadEventAdm(){
      //console.log('cl');

      //this.selected_table = [];
      //this.flagSetMongerArmour = false;

      let t = this.prepareDataHall();
      //console.log(t);

      this.$refs.hall.prepareHall(t);




    },

    updateMode: function () {

      // выбрали уполномоченых - сбросили на бронь на ПОСТАВИТЬ
      //this.EnvoyModeSetArmour = true

      this.$nextTick(() => {

        // let data = {
        //   view: this.getViewById(this.view),
        //   options: this.getOptions(this.options),
        //   additional: this.getAdditional(this.additional),
        // }

        //let t = this.prepareDataHall();

        //console.log(data);
        //this.$refs.hall.updateMode(t);


        this.$nextTick(() => {
          this.$refs.hall.full_redraw();
          this.$nextTick(() => {
            this.$refs.hall.full_redraw();
          })
        })

      })


    },

    prepareDataHall(){

      let data = {

        type: this.hallConfig.base.type,
        mode: this.hallConfig.base.mode,

        view: this.hallConfig.base.view,
        options: this.hallConfig.options,

        additional: this.hallConfig.additional,

        event_id: this.hallConfig.base.event_id,
        scale:0.5

      }

      //console.log(data);

      return data;

    },



    getSelectedSeats(){

      //this.$refs.hall.getSelected(true);

    },

    clearSelected(){

      //this.$refs.hall.clearSelected();

    },

    doOrder(){

      console.log('order');

      let post_data = {

        base:{
          email:"mail",
          total:"700",
          user:1,
          mode:"kassa"
        },

        orders:[
          {
            event:1,
            price:700,

            seats:[
              7148, 7157
            ]
          }

        ]

      }
      console.log(post_data);

      let pr2 = this.$store.state.api.addOrder(post_data);

      pr2.then( data =>{

        console.log(data);
        // resp : id only
        //
        // this.layouts = data;
        // this.layouts_ready = true;
      })

    },

  }
}



</script>

<style scoped>

</style>
